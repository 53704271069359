<script>
    const SLACK_URI = 'https://hooks.slack.com/services/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';
    export default {
        methods: {
            sendSlackLog(title, message, type = 'danger') {
                const p = {
                    attachments: [
                        {
                            'fallback': 'Sistema Effecti',
                            'title': title,
                            'text': message,
                            'color': type,
                        },
                    ],
                };
                return this.axios.post(SLACK_URI, p, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded' // avoids CORS
                    }
                });
            }
        }
    }
</script>