import Vue from 'vue'
import VueRouter from 'vue-router';
import App from './App.vue'
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.config.productionTip = false;

Vue.use(VueRouter);
const router = new VueRouter({
    routes: [],
});

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
Vue.use(VueAxios, axios);

new Vue({
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app');
