<template>
    <div ref="chatContainer">
        <iframe :src="getIframeSrc()"></iframe>
    </div>
</template>

<script>
    export default {
        name: "Chat",
        props: {
            appKey: {
                type: String,
                required: true,
            },
            clientData: {
                type: Object,
                required: true,
            }
        },
        methods: {
            getIframeSrc() {
              return `https://chat.movidesk.com/ChatWidget/Login/${this.appKey}?${this.urlEncodeGetParams()}`;
            },
            urlEncodeGetParams() {
                return Object.keys(this.clientData)
                    .map((key) => this.clientData[key] === null ? null : `${key}=${this.clientData[key]}`)
                    .filter(val => val !== null)
                    .join('&');
            }
        }
    }
</script>

<style scoped>
    iframe {
        overflow: hidden;
        width: 100%;
        min-height: 450px;
        border: none;
    }
</style>