<template>
    <v-app>
        <v-content>
            <Widget/>
        </v-content>
    </v-app>
</template>

<script>
    import Widget from "./components/Widget";

    export default {
        name: 'App',
        components: {
            Widget,
        },
        data: () => ({
        }),
    };
</script>

<style>
    body, html, #app {
        background: transparent;
        overflow: hidden;
    }
    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
</style>
