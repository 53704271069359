<template>
    <div>
        <v-card style="width: 340px; border-radius: 8px; margin: 5px;">
            <v-card-title class="align-center justify-center" id="widgetMainCardTitle">
                <div class="col-2">
                    <v-icon @click="dialog = true" dark color="#fff">mdi-exit-to-app</v-icon>
                </div>
                <div class="col-7">
                    <h5>Atendimento Effecti</h5>
                </div>
                <div class="col-3">
                    <v-img src="https://s3.amazonaws.com/movidesk-files/7FE1780D08F72D705580A302E280874D"
                           width="45px"
                           height="45px"/>
                </div>
            </v-card-title>
            <v-card-text style="padding: 10px;">
                <div class="text-center" v-if="isLoading">
                    <v-progress-circular :size="50" color="rgb(77, 139, 191)" indeterminate/>
                </div>
                <Chat v-if="isChat" :client-data="clientData" :app-key="appKey"/>
                <Ticket v-if="isTicket" :client-data="clientData" @closeWidget="exitWidget"/>

                <div v-if="!isLoading">
                    <v-dialog v-model="dialog" max-width="350" overlay-opacity="0">
                        <v-card>
                            <v-card-title class="text-center">
                                <h5>Confirmação</h5>
                            </v-card-title>
                            <v-divider class="py-2"></v-divider>
                            <v-card-text>
                                Você deseja realmente sair?
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn dark @click="dialog = false">
                                    Não
                                </v-btn>
                                <v-btn @click="exitWidget">
                                    Sim
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>

                <div v-if="!isLoading && hasHashError">
                    <h3>Ooops, desculpe-nos :(</h3>
                    <br>
                    <p>Houve um problema ao resgatar suas informações. Por favor, tente novamente.</p>
                    <p>Caso o problema persista, entre em contato conosco via e-mail ou WhatsApp.</p>
                    <p>Saiba como em <a href="https://www.effecti.com.br/contato/" target="_blank">effecti.com.br/contato</a>.</p>
                </div>

                <!-- Hack para não precisar do CORS para sair do chat. -->
                <iframe :src="chatExitSrc" style="display: none;"></iframe>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="errorSnackbar" color="error">
            <p><strong>Oops :(, </strong>tivemos algum problema ao carregar seus dados.</p>
        </v-snackbar>
    </div>
</template>

<script>
    import Chat from "./Chat";
    import Ticket from "./Ticket";
    import SlackMixin from '../mixins/Slack';

    export default {
        name: "Widget",
        components: {
            Chat,
            Ticket,
        },
        mixins: [
            SlackMixin,
        ],
        data() {
            return {
                isChat: false,
                isTicket: false,
                isLoading: true,
                dialog: false,
                hasHashError: false,
                errorSnackbar: false,
                chatExitSrc: '',
                appKey: '',
                apiParams: {
                    user: '',
                    password: '',
                    detail: null
                },
                clientData: {
                    Name: '',
                    Email: null,
                    PhoneNumber: null,
                    CodeReference: '',
                    OrganizationCodeReference: null,
                    StayConnected: false,
                    EmptySubject: false,
                    StartChat: false,
                },
                api: {
                    chatKey:{
                      get: process.env.NODE_ENV === 'production'
                          ? 'https://api.effecti.com.br/ProxyMovidesk.php?chatKey'
                          : 'http://localhost/api/ProxyMovidesk.php?chatKey'
                    }
                }

            };
        },
        mounted() {
            this.registerEventListener();
            if(this.populateRequiredParameters()) {
                this.fetchClientData();
            } else {
                /*this.sendSlackLog(
                    'Movidesk Widget',
                    'Parâmetros necessários não informados.\nParams: ' + JSON.stringify(this.$route.query)
                );*/
                this.isLoading = false;
                this.hasHashError = true;
                this.errorSnackbar = true;
            }
        },
        methods: {
            registerEventListener() {
                window.addEventListener('message', evt => {
                    if(evt.data.type === 'closeWidget') {
                        this.dialog = true;
                    }
                });
            },
            populateRequiredParameters() {
                if(this.$route.query.u && this.$route.query.p) {
                    this.apiParams.user = this.$route.query.u;
                    this.apiParams.password = this.$route.query.p;
                    return true;
                }

                if(this.$el.baseURI) {
                    let uri = new URL(this.$el.baseURI);
                    if(uri.searchParams.get('u') && uri.searchParams.get('p')) {
                        this.apiParams.user = uri.searchParams.get('u');
                        this.apiParams.password = uri.searchParams.get('p');
                        this.apiParams.detail = uri.searchParams.get('d');

                        return true;
                    }
                }
                return false;
            },
            fetchClientData() {
                const urlMiddleware = 'https://mdw.minha.effecti.com.br/v1/companies/chat-widget';
                const urlLocalhost = 'http://localhost:9090/v1/companies/chat-widget';
                const url = process.env.NODE_ENV === 'production'
                    ? `${urlMiddleware}?user=${this.apiParams.user}&password=${this.apiParams.password}`
                    : `${urlLocalhost}?user=${this.apiParams.user}&password=${this.apiParams.password}`;

                return this.$http.get(url).then(response => {
                    if(response.status === 200) {
                        let d = response.data;
                        console.log(d);
                        this.clientData.CodeReference = d.clientRefCode;
                        this.clientData.Name = d.clientName;
                        this.clientData.Email = d.clientEmail;
                        this.clientData.PhoneNumber = d.phoneNumber;
                        this.isLoading = false;
                        this.isTicket = d.ticket;
                        this.isChat = d.chat;
                        this.getAppKey();
                    } else {
                        /*this.sendSlackLog(
                            'Movidesk Widget',
                            'Erro ao buscar os dados do cliente.\n Resposta: ' + response.data
                            + '\nCódigo: ' + response.status
                            + '\nAPI Params: ' + JSON.stringify(this.apiParams)
                        );*/
                        this.isLoading = false;
                        this.hasHashError = true;
                        this.errorSnackbar = true;
                    }
                }).catch(error => {
                    /*this.sendSlackLog(
                        'Movidesk Widget',
                        'Erro ao buscar os dados do cliente.\n Resposta: ' + error.response.data
                        + '\nCódigo: ' + error.response.status
                        + '\nAPI Params: ' + JSON.stringify(this.apiParams)
                    );*/
                    console.log(error);
                    this.isLoading = false;
                    this.hasHashError = true;
                    this.errorSnackbar = true;
                });
            },
            exitWidget() {
                if (this.isChat) {
                    this.chatExitSrc = `https://chat.movidesk.com/ChatWidget/ChatExit/${this.appKey}`;
                }

                this.dialog = false;
                this.isLoading = true;
                this.isChat = false;
                this.isTicket = false;

                // Give the logout iFrame some time to load.
                setTimeout(() => {
                    parent.postMessage({
                        type: 'destroyWidget',
                        data: {}
                    }, '*');
                }, 400);
            },
            getAppKey(){
                let url = this.api.chatKey.get + '&id=' + this.clientData.CodeReference;
                if(this.apiParams.detail != null) {
                    url += "&detail="+this.apiParams.detail; 
                }

                return this.$http.get(url).then(response => {
                  if(response.status === 200) {
                    this.appKey = response.data;
                  }
                })
            },

        }
    }
</script>

<style>
    html, body {
        overflow: hidden;
    }
</style>

<style scoped>
    #widgetMainCardTitle {
        background-color: rgb(77, 139, 191);
        color: #fff;
        padding: 4px;
    }
</style>
