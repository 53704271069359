<template>
    <div>
        <v-form ref="form" v-if="!ticketSuccessfulySent && !isLoading" :lazy-validation="true" keep-alive>
            <p>Olá!</p>
            <p>Para melhor atende-lo, por favor, preencha os dados abaixo. Responderemos seu contato o mais breve
                possível.</p>
            <v-text-field
                    label="Assunto"
                    :rules="rules.subject"
                    v-model="formData.subject"/>
            <v-textarea
                    label="Descrição"
                    :rules="rules.description"
                    v-model="formData.description"/>
            <v-row class="justify-end px-2">
                <v-btn color="error"
                       class="mr-4"
                       @click="reset">
                    Limpar
                </v-btn>
                <v-btn color="rgb(77, 139, 191)"
                       dark
                       class="justify-end"
                       @click="validate">
                    Enviar
                </v-btn>
            </v-row>
        </v-form>

        <div class="text-center" v-if="isLoading">
            <v-progress-circular :size="50" color="rgb(77, 139, 191)" indeterminate/>
        </div>

        <div v-if="ticketSuccessfulySent && !isLoading">
            <div class="row">
                <div class="col">
                    <h3 class="headline">Ticket enviado!</h3>
                    <br>
                    <p>Seu ticket foi recebido com sucesso!</p>
                    <p>Em breve entraremos em contato.</p>
                    <v-btn color="success" @click="$emit('closeWidget')">
                        <v-icon left>mdi-check</v-icon> Certo!
                    </v-btn>
                </div>
            </div>
        </div>

        <v-snackbar v-model="errorSnackbar"
                    color="error">
            <p><strong>Oops :(, </strong>tivemos algum problema ao enviar seu ticket.</p>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        name: "Ticket",
        props: {
            clientData: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                formData: {
                    subject: '',
                    description: '',
                },
                postData: {
                    type: 2,
                    subject: '',
                    category: 'Ticket Chat',
                    status: 'Aguardando Atendente',
                    origin: 5,
                    createdBy: {
                        id: '',
                    },
                    clients: [],
                    actions: [],
                },
                postDataClient: {
                    id: null,
                    isActive: true,
                    personType: 2,
                    profileType: 2,
                    businessName: null,
                    emails: [],
                },
                rules: {
                    subject: [
                        v => !!v || 'O assunto é obrigatório',
                        v => (v && v.length >= 6) || 'O assunto deve ter ao menos 6 caracteres.'
                    ],
                    description: [
                        v => !!v || 'A descrição é obrigatória',
                        v => (v && v.length >= 10) || 'A descrição deve ter ao menos 10 caracteres.'
                    ],
                },
                api: {
                    tickets: {
                        // post: 'http://localhost/framework/api/ProxyMovidesk.php?postTicket',
                        post: process.env.NODE_ENV === 'production'
                            ? 'https://api.effecti.com.br/ProxyMovidesk.php?postTicket'
                            : 'http://localhost/framework/api/ProxyMovidesk.php?postTicket'
                    },
                    clientExists: {
                        // get: 'http://localhost/framework/api/ProxyMovidesk.php?clientExists',
                        get: process.env.NODE_ENV === 'production'
                            ? 'https://api.effecti.com.br/ProxyMovidesk.php?clientExists'
                            : 'http://localhost/framework/api/ProxyMovidesk.php?clientExists'
                    },
                    postClient: {
                        // post: 'http://localhost/framework/api/ProxyMovidesk.php?postClient',
                        post: process.env.NODE_ENV === 'production'
                            ? 'https://api.effecti.com.br/ProxyMovidesk.php?postClient'
                            : 'http://localhost/framework/api/ProxyMovidesk.php?postClient'
                    }
                },
                isLoading: false,
                ticketSuccessfulySent: false,
                errorSnackbar: false,
            };
        },
        methods: {
            async validate() {
                if(this.$refs.form.validate()) {
                    this.isLoading = true;
                    try {
                        if(await this.validateClientExists()) {
                            await this.insertTicket();
                        } else {
                            if(await this.insertClient()) {
                                await this.insertTicket();
                            } else {
                                this.errorSnackbar = true;
                                console.log('Falha ao inserir o cliente.');
                            }
                        }
                    } catch(error) {
                        console.log(error);
                        this.isLoading = false;
                        this.errorSnackbar = true;
                    }
                    this.isLoading = false;
                }
            },
            validateClientExists() {
                const url = this.api.clientExists.get + '&id=' + this.clientData.CodeReference;
                return this.$http.get(url).then(response => {
                    if(response.status === 200) {
                        return response.data.exists;
                    } else {
                        return false;
                    }
                })
            },
            insertClient() {
                this.postDataClient.id = this.clientData.CodeReference;
                this.postDataClient.businessName = this.clientData.Name;
                this.postDataClient.personType = 2;
                this.postDataClient.profileType = 2;
                this.addClientEmails();
                return this.$http.post(this.api.postClient.post, this.postDataClient).then(response => {
                    if(response.status === 200) {
                        return !!response.data.id;
                    } else {
                        return false;
                    }
                });
            },
            insertTicket() {
                this.postData.subject = this.formData.subject;
                this.addTicketClient();
                this.addTicketActions();
                this.addTicketCreatedBy();

                return this.$http.post(this.api.tickets.post, this.postData).then(response => {
                    if(response.status === 200) {
                        this.ticketSuccessfulySent = true;
                    } else {
                        this.errorSnackbar = true;
                        console.log(response);
                    }
                });
            },
            reset() {
                this.$refs.form.reset();
            },
            addTicketClient() {
                this.postData.clients = [
                    {
                        id: this.clientData.CodeReference,
                        businessName: this.clientData.Name,
                        email: this.clientData.Email,
                        personType: 2,
                        profileType: 2,
                    }
                ];
            },
            addTicketActions() {
                this.postData.actions = [{
                    type: 2,
                    origin: 0,
                    description: this.formData.description,
                }];
            },
            addTicketCreatedBy() {
                this.postData.createdBy = {
                    id: this.clientData.CodeReference,
                    businessName: this.clientData.Name,
                    email: this.clientData.Email,
                    personType: 2,
                    profileType: 2,
                };
            },
            addClientEmails() {
                this.postDataClient.emails = [{
                    emailType: 'Profissional',
                    email: this.clientData.Email,
                    isDefault: true,
                }];
            }
        },
    }
</script>

<style scoped>

</style>